import React, { useEffect } from 'react';

const JotformWithGTMQualifyUpdated = () => {
  useEffect(() => {
    // Load GTM script
    const gtmScript = document.createElement('script');
    gtmScript.async = true;
    gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-WCMP9ZL';
    document.head.appendChild(gtmScript);

    // Event listener for handling iframe messages
    const handleIFrameMessage = (e) => {
      if (e.data === 'iframeLoaded') {
        window.parent.scrollTo(0, 0);
      }
    };

    if (window.addEventListener) {
      window.addEventListener('message', handleIFrameMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', handleIFrameMessage);
    }

    // Cleanup on component unmount
    return () => {
      if (window.removeEventListener) {
        window.removeEventListener('message', handleIFrameMessage);
      } else if (window.detachEvent) {
        window.detachEvent('onmessage', handleIFrameMessage);
      }
    };
  }, []);

  return (
    <div>
      {/* Google Tag Manager iframe */}
      <iframe
        title="GTM"
        src="https://www.googletagmanager.com/ns.html?id=GTM-WCMP9ZL"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>

      {/* Jotform iframe with hardcoded comet_token */}
      <iframe
        id="JotFormIFrame-231798947017065"
        title="Do You Qualify for an 831(b) Plan?"
        allowtransparency="true"
        allow="geolocation; microphone; camera"
        src="https://form.jotform.com/231798947017065?comet_token=4503599668000008|3UZbwqFMvkoyqxJhJOB7Kzq0eh7f3tj4noesAFEn908e689c"
        frameBorder="0"
        style={{ minWidth: '100%', maxWidth: '100%', height: '700px', border: 'none' }}
        scrolling="no"
      ></iframe>
    </div>
  );
};

export default JotformWithGTMQualifyUpdated;